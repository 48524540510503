import React, { useEffect, useState } from 'react';

import {
  BoardTicket,
  Lead,
  Marketplace,
  ShareItem
} from '@/libs/prompto-api/src';
import { useNavigate, useParams } from 'react-router-dom';
import { setLanguage, getCurrentLanguage } from 'src/localization/localizer';
import { useAppState } from 'src/store/AppStore';
import { buildAssetURIWithOptions } from 'src/helpers/uri';
import PromptoLogoWhite from 'src/resources/images/prompto-logo-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import localizer from 'src/localization/localizer';

// Styling
import styled from 'styled-components';
import axios from 'axios';
import { forBiggerThan } from 'src/helpers/ui';
import env from 'src/environment';
import { fetchSettingsFromURL } from 'src/helpers/utils';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

const LanguageSwitcher = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  color: white;
  gap: 15px;
  font-size: 0.75rem;
  align-items: end;
  justify-content: flex-end;
  user-select: none;
  cursor: pointer;
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background-color: #fff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
`;

const FormWrapper = styled.div`
  position: absolute;
  top: 0;
  overflow: auto;
  height: 100%;
  max-width: 500px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  background: rgba(0, 0, 0, 0.75);
  // background: linear-gradient(
  //   180deg,
  //   rgba(0, 0, 0, 0.75) 0%,
  //   rgba(0, 0, 0, 0.75) 75%,
  //   rgba(0, 0, 0, 0) 100%
  // );

  padding: 15px 25px;
  align-items: center;

  ${forBiggerThan.tablet`
  left: 15%;
  `}
`;
const ProjectName = styled.div`
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const ProjectAddress = styled.div`
  color: white;
  font-size: 1rem;
  margin-bottom: 15px;
`;
const ProjectDetails = styled.div`
  color: white;
  font-size: 1.5rem;
  margin-bottom: 25px;
  margin-top: 25px;
  text-align: center;
`;

const ProjectInfoWrapper = styled.div`
  max-width: 100%;
  margin-top: auto;
  margin-bottom: auto;
`;

const ProjectInfoItem = styled.div`
  color: white;
  font-size: 1rem;
  margin-bottom: 15px;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;
const Policy = styled.div`
  color: white;
  font-size: 0.6rem;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  padding: 2px 3px;
  cursor: pointer;
`;

const FormDetailsWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
`;

const InputField = styled.input`
  width: 100%;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.gray300};
  padding: 14px 12px;
  outline: inherit;
  box-sizing: border-box;
`;

const StyledButton = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.gold};
  user-select: none;
  cursor: pointer;
  padding: 14px 12px;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  color: white;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const Divider = styled.div`
  min-height: 1px;
  width: 100%;
  background-color: white;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 8px;
  padding: 5px 10px;
  margin-top: auto;
  //background-color: ${({ theme }) => theme.gray95};
`;

const Logo = styled.img`
  height: 100%;
  max-height: 120px;
  max-width: 120px;
  object-fit: contain;
  object-position: center;
`;

const LogoDivider = styled.div`
  box-shadow: inset 1px 0 0 0 ${({ theme }) => theme.white};
  width: 1px;
  height: 20px;
  margin-right: 15px;
`;

const LogoText = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.white};
  text-align: center;
`;

const clientSize = 100;

const ClientCircle = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  background: white;
  border-radius: 50%;
  height: ${clientSize / 2.0}px;
  width: ${clientSize / 2.0}px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${forBiggerThan.tablet`
      left: unset;
    right: 15px;
    height: ${clientSize}px;
    width: 100px;
  `}
`;

const ClientLogo = styled(Logo)`
  max-height: ${clientSize / 2.0 - 20}px;
  max-width: ${clientSize / 2.0 - 20}px;

  ${forBiggerThan.tablet`
  max-height: ${clientSize - 20}px;
  max-width: 80px;
  `}
`;

const AddressIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`;

const Disclaimer = styled.div`
  color: white;
  font-size: 0.75rem;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.redBright};
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const phoneRegExp = /^\+?\d*$/;
const emailRegExp = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

const ProjectFormPage = () => {
  // App state
  const { AppState } = useAppState();
  const { marketplaceId } = AppState;

  const navigate = useNavigate();

  const { code } = useParams<string>();

  const [vaultId, setVaultId] = useState<string>('');
  const [projectId, setProjectId] = useState<string>('');

  const [companyLogoUri, setCompanyLogoUri] = useState<string>('');
  const [backgroundImage, setBackgroundImage] = useState<string>('');

  const [fileFetched, setFileFetched] = useState<boolean>(false);
  const [localLanguage, setLocalLanguage] = useState<string>('nl');

  // Form data
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [postcodeError, setPostcodeError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');

  const [projectData, setProjectData] = useState<any>();
  const [localizedData, setLocalizedData] = useState<any>();

  const [processing, setProcessing] = useState<boolean>(false);

  // Get project from code
  useEffect(() => {
    if (code) {
      ShareItem.getByCode(code).then((result: any) => {
        if (result.data) {
          const { vaultObjectId, projectObjectId, queryParams } =
            result.data.shareItem;

          setVaultId(vaultObjectId);
          setProjectId(projectObjectId);

          const params = new URLSearchParams(queryParams);
          const paramsObject: any = {};
          // Iterate over the URLSearchParams entries and add them to the object
          for (const [key, value] of params.entries()) {
            paramsObject[key] = value;
          }

          if (paramsObject.language) {
            setLanguage(paramsObject.language);
            setLocalLanguage(paramsObject.language);
          }

          const { language } = fetchSettingsFromURL();
          if (language) {
            setLanguage(language as string);
            setLocalLanguage(language as string);
          }
        }
      });
    }
  }, [code]);

  useEffect(() => {
    if (marketplaceId && vaultId && projectId) {
      Marketplace.getMarketplaceProjectResources(
        marketplaceId,
        vaultId,
        projectId,
        1
      ).then((result: any) => {
        if (result?.data?.vmContentItemList?.length > 0) {
          const backgroundUrl = buildAssetURIWithOptions(
            'o=true',
            result.data.vmContentItemList[0].contentUri
          );
          setBackgroundImage(backgroundUrl);
        }
      });

      Marketplace.getMarketplaceProjectAndUnits(
        marketplaceId,
        vaultId,
        projectId
      ).then((result) => {
        if (result?.data) {
          const { logoUri } = result.data;

          setCompanyLogoUri(buildAssetURIWithOptions('h=120', logoUri));
        }
      });
    }
  }, [vaultId, projectId, marketplaceId]);

  const readPublicJsonFile = async () => {
    const url =
      'https://storage.googleapis.com/assets.prompto.com/Marketplace/ProjectForms/projectForms.json';

    axios.get(url).then(async (response) => {
      if (response?.data) {
        const match = response.data.find(
          (item: any) => item.shareCode === code
        );
        if (match) {
          setProjectData(match);
        } else {
          navigate('/projects');
        }
      }
    });
  };

  useEffect(() => {
    if (!fileFetched) {
      readPublicJsonFile();
      setFileFetched(true);
    }
  }, [fileFetched]);

  useEffect(() => {
    if (localLanguage && projectData) {
      setLocalizedData(projectData.textMap[localLanguage]);
    }
  }, [localLanguage, projectData]);

  const onSubmitForm = () => {
    if (processing) return;

    const emailValid = emailRegExp.test(email);
    let phoneValid = phoneRegExp.test(phone);
    phoneValid = phoneValid && (phone.includes('+32') || phone.includes('04'));
    if (phone.includes('+32')) {
      phoneValid = phone.length >= 12;
    }
    if (phone.includes('04')) {
      phoneValid = phone.length >= 10;
    }

    if (!email) {
      setEmailError(localizer.getInTouchForm.formErrors.emailRequired);
    }
    if (!emailValid) {
      setEmailError(localizer.getInTouchForm.formErrors.invalidEmailAddress);
    }

    if (!phone) {
      setPhoneError(localizer.getInTouchForm.formErrors.phoneNumberRequired);
    }
    if (!phoneValid) {
      setPhoneError(localizer.getInTouchForm.formErrors.invalidPhoneNumber);
    }

    if (!firstName) {
      setFirstNameError(localizer.getInTouchForm.formErrors.firstNameRequired);
    }
    if (!lastName) {
      setLastNameError(localizer.getInTouchForm.formErrors.lastNameRequired);
    }
    if (!postcode) {
      setPostcodeError(localizer.getInTouchForm.formErrors.postcodeRequired);
    }

    if (emailValid && phoneValid && firstName && lastName && postcode) {
      setProcessing(true);

      Lead.create({
        leadCreateExternalParams: {
          vaultId: 'o8vIioB4ja',
          projectId: '9QFejdRRJ8',
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          postcode: postcode,
          language: getCurrentLanguage(),
          utmSource: 'prompto',
          utmCampaign: 'projectForm',
          additionalFields: {
            projectId: projectId
          }
        }
      }).then((result) => {
        const boardTicketObjectId = result.data.boardTicketObjectId;

        if (boardTicketObjectId) {
          const moveParams: any = {
            ticketIdList: [boardTicketObjectId],
            sourceColumnId: 'uyVS4dOzPm', // Source and target are hardcoded columns from the admin lead management board
            targetColumnId: 'lzmkUP8ajB'
          };

          BoardTicket.move(moveParams, env().integrationToken).then(
            (result) => {
              navigate(`/project/${code}${window.location.search}`);
            }
          );
        } else {
          navigate(`/project/${code}${window.location.search}`);
        }
      });
    }
  };

  return (
    <Wrapper>
      {/* <BlurOverlay /> */}
      <BackgroundWrapper>
        <BackgroundImage src={backgroundImage} />
      </BackgroundWrapper>
      <FormWrapper>
        <LanguageSwitcher>
          <div
            onClick={() => {
              console.log('set nl');
              setLanguage('nl');
              setLocalLanguage('nl');
            }}
          >
            NL
          </div>
          <div
            onClick={() => {
              console.log('set fr');
              setLanguage('fr');
              setLocalLanguage('fr');
            }}
          >
            FR
          </div>
          <div
            onClick={() => {
              setLanguage('en');
              setLocalLanguage('en');
            }}
          >
            EN
          </div>
        </LanguageSwitcher>
        <ProjectName>{localizedData?.projectName}</ProjectName>
        <ProjectAddress>
          <AddressIcon icon={['far', 'map-marker-alt']} size="sm" />{' '}
          {localizedData?.address}
        </ProjectAddress>
        <Divider />
        <ProjectDetails>{localizedData?.description}</ProjectDetails>
        <ProjectInfoWrapper>
          {localizedData?.USPs?.map((x: string) => {
            return <ProjectInfoItem>{x}</ProjectInfoItem>;
          })}
        </ProjectInfoWrapper>
        <FormDetailsWrapper>
          <HorizontalWrapper>
            <VerticalWrapper>
              <InputField
                name={'firstName'}
                value={firstName}
                maxLength={24}
                placeholder={`${localizer.getInTouchForm.firstNameLabel}*`}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFirstNameError('');
                }}
              />

              <ErrorMessage>{firstNameError}</ErrorMessage>
            </VerticalWrapper>
            <VerticalWrapper>
              <InputField
                name={'lastName'}
                value={lastName}
                maxLength={24}
                placeholder={`${localizer.getInTouchForm.lastNameLabel}*`}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setLastNameError('');
                }}
              />
              <ErrorMessage>{lastNameError}</ErrorMessage>
            </VerticalWrapper>
          </HorizontalWrapper>
          <InputField
            name={'email'}
            value={email}
            maxLength={64}
            placeholder={`${localizer.getInTouchForm.emailAddressLabel}*`}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError('');
            }}
          />
          <ErrorMessage>{emailError}</ErrorMessage>
          <InputField
            name={'postcode'}
            value={postcode}
            maxLength={12}
            placeholder={`${localizer.getInTouchForm.postcodeLabel}*`}
            onChange={(e) => {
              setPostcode(e.target.value);
              setPostcodeError('');
            }}
          />
          <ErrorMessage>{postcodeError}</ErrorMessage>
          <InputField
            name={'phone'}
            value={phone}
            maxLength={16}
            placeholder={`${localizer.getInTouchForm.phoneNumberLabel}*`}
            onChange={(e) => {
              setPhone(e.target.value);
              setPhoneError('');
            }}
          />
          <ErrorMessage>{phoneError}</ErrorMessage>
          <Disclaimer>{localizer.getInTouchForm.disclaimer}</Disclaimer>
          <StyledButton onClick={onSubmitForm}>
            {processing
              ? localizer.loginPage.processing
              : localizer.getInTouchForm.moreInfo}
          </StyledButton>
        </FormDetailsWrapper>
        <LogoContainer>
          <HorizontalWrapper>
            <Logo src={PromptoLogoWhite} alt="Prompto logo" />
            <LogoDivider />
            <LogoText>{localizer.header.slogan}</LogoText>
          </HorizontalWrapper>
        </LogoContainer>
        <Policy
          onClick={() => {
            window.open('https://prompto.com/privacy-policy', '_blank');
          }}
        >
          {localizer.registerPage.pp}
        </Policy>
      </FormWrapper>
      <ClientCircle>
        <ClientLogo src={companyLogoUri} alt="Client logo" />
      </ClientCircle>
    </Wrapper>
  );
};

export default ProjectFormPage;
